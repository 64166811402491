<template>
  <div class="box-wrapper">
    <div class="box-content">
      <div class="mt-4 mb-4 text-center">
        <h4 class="title">{{ $t("ERROR.NOT_FOUND") }}</h4>
        <router-link class="btn btn-primary" :to="{ name: 'Login' }">{{
          $t("BACK")
        }}</router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
  computed: {},
  mounted() {},
};
</script>
<style></style>
